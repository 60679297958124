@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300&display=swap');

html, body {
  width: 100%;
  height: 100%;
  background: hsla(242, 58%, 73%, 1);
  background: linear-gradient(90deg, hsla(242, 58%, 73%, 1) 0%, hsla(157, 72%, 82%, 1) 100%);
  background: -moz-linear-gradient(90deg, hsla(242, 58%, 73%, 1) 0%, hsla(157, 72%, 82%, 1) 100%);
  background: -webkit-linear-gradient(90deg, hsla(242, 58%, 73%, 1) 0%, hsla(157, 72%, 82%, 1) 100%);
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#9491E2", endColorstr="#AFF2D8", GradientType=1 );
}
* {
  font-family: 'Rubik', sans-serif !important;
}
.App {
  text-align: center;
}
.table_r {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.td_r, th_r {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.tr_r:nth-child(even) {
  background-color: #dddddd;
}
.body_card {
  margin-top: 0%;
  max-width: 90%;
  min-height: 500px;
}
.body_card_clients {
  margin-top: 0%;
  max-width: 100%;
  min-height: 90vh;
}
.tablecheck_form {

}
.tfield {
  direction: rtl;
  text-align: right;
}


.tableFixHeader {
  overflow-y: auto;
  max-height: 600px;
}

.tableFixHeader thead th {
  position: sticky;
  top: 0px;
  z-index: 1;
}

table {
  border-collapse: collapse;
  width: 100%;
  overflow: unset !important;
  position: relative;
}

thead th {
  background: $ultra-light-grey !important;
  box-shadow: 1px 0px 0px 1px $silver;
}

table th .css-yk16xz-control {
  background: transparent;
}

.MuiDrawer-paperAnchorRight {
  width: 30% !important;
  text-align: right;
  background: hsla(242, 58%, 73%, 1);
  background: linear-gradient(90deg, hsla(242, 58%, 73%, 1) 0%, hsla(157, 72%, 82%, 1) 100%);
  background: -moz-linear-gradient(90deg, hsla(242, 58%, 73%, 1) 0%, hsla(157, 72%, 82%, 1) 100%);
  background: -webkit-linear-gradient(90deg, hsla(242, 58%, 73%, 1) 0%, hsla(157, 72%, 82%, 1) 100%);
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#9491E2", endColorstr="#AFF2D8", GradientType=1 );
}

.drawer_option {
  text-align: center;
  margin-top: 5%;
  margin-bottom: 5%;
}
.drawer_option:hover {
  background-color: white;
  text-align: center;
  margin-top: 5%;
  margin-bottom: 5%;
}
.option_border {
  border-bottom: 3px solid black;
}
.drawer_option a {
  margin-bottom: 2%;
  text-decoration: none;
  color: black;
  font-weight: bold;
  font-size: 1.1em;
}
